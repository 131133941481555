import { useEffect } from "react";
/**
 * ページタイトルを追加する
 *
 * @param  title ページ毎に追加したいタイトル
 * @return
 */
export function useDocumentTitle(title: string | undefined): void {
    useEffect(() => {
        const originalTitle = document.title;

        document.title = title
            ? `${title} | 製品リモート監視`
            : "製品リモート監視";

        return () => {
            document.title = originalTitle;
        };
    }, [title]);
}
